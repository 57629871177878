<template>
  <div>
    <div class="commonInputTopBox">
      <pageHeader :pagename="'设备品牌管理'" :total="total" />

      <CommonHeaders
        :formConfig="formConfig"
        :formModel="formModel"
        :buttons="buttons"
        @button-click="handleButtonClick"
      ></CommonHeaders>

    </div>
    
    <div class="commonControl-body">
      <common-table
        :tableData="tableData"
        :tableButton="tableButton"
        :tableLabel="tableLabel"
        @edit="editFirstLevel"
      ></common-table>
      <div class="layoutBorder">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 点击事件触发 -->
    <el-dialog title="编辑设备" :visible.sync="dialogFormVisibleEdit">
      <el-form :model="formEdit">
        <el-form-item label="设备id" label-width="80px">
          <el-input v-model="formEdit.id" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="品牌名" label-width="80px">
          <el-input v-model="formEdit.brand_name" autocomplete="off"></el-input>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="updateFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增设备品牌" :visible.sync="dialogFormVisibleAdd">
      <el-form :model="formAdd">
        <el-form-item label="设备品牌名" label-width="120px">
          <el-input v-model="formAdd.brand_name" autocomplete="off"></el-input>
        </el-form-item>
       
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="createFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {
  equipment_brand_get,
  equipment_brand_create,
  equipment_brand_update,
  equipment_brand_delete
} from "@/api/manage/operation/equipment/equipment.js";

import CommonTable from "frontelementpackage/src/CommonTableButtonFixed.vue";
import CommonHeaders from "@/components/shibo_Common/CommonHeaders.vue";

export default {
  name: "start",
  data() {
    return {
      query: {
        querypage: 1, //获取页码
        pagesize: 10, //每页返回数量
        keyword: "", //搜索 可搜邮箱 用户名 手机号
      },
      //分页数据
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData: [],
      dialogFormVisibleEdit: false,
      formEdit: {},
      dialogFormVisibleAdd: false,
      formAdd: {},
      tableLabel: [
        {
          prop: "id",
          label: "id",
          // width: 300,
          minWidth:"20%"
          // position:"right"
        },
        {
          prop: "brand_name",
          label: "设备品牌名",
          // width: 500,
          minWidth:"20%"
        },
        
      ],
      tableButton: {
        width: 200,
        data: [
          {
            name: "编辑",
            type: "primary",
            size: "small",
          },

          {
            name: "删除",
            type: "danger",
            size: "small",
          },
        ],
      },
      total: null,
      //过滤数据
      formConfig: [
        {
          label: '设备分类名称',
          prop: 'keyword',
          component: 'el-input',
          placeholder: '设备分类名称'
        }
      ],
      formModel: {},
      buttons: [
        { label: "新增设备品牌", type: "primary",action:'create' },
      ]
    };
  },
  components: {
    CommonTable,
    CommonHeaders
  },
  created() {
    this.getFirstLevel();
  },
  methods: {

    gotoUser(row) {
      // this.formEdit = {
      //   group_id: row.id,
      //   group_name: row.group_name,
      //   group_info: row.group_info,
      // };
      // console.log(this.form,"ddd")
      // this.dialogFormVisibleEdit = true;
      row;
      // this.$router.push({ name: "productLibraryInfo", query:   row  });
    },
    editFirstLevel(row, methods) {
      if (methods == "编辑") {
        this.editUser(row);
      }
      if (methods == "编辑附件") {
        this.gotoUser(row);
      }
      if (methods == "删除") {
        this.delUser(row);
      }
    },
    editUser(row) {
      this.formEdit = row;
      // console.log(this.form,"ddd")
      this.dialogFormVisibleEdit = true;
      // row;
      // this.$router.push({ name: "productLibraryInfo", query: { id: row.id } });
    },
    delUser(row) {
      let ids = row.id;
      console.log({ group_id: ids });
      this.$confirm("是否确定删除", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "删除",
        cancelButtonText: "放弃删除",
      })
        .then(() => {
          equipment_brand_delete({
            brand_id: ids,
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success("成功删除");
              this.$router.go(0);
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃删除" : "停留在当前",
          });
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: "",
      };
      //  console.log("当前页面显示xxx条", this.currentPage,"当前界面是xxx页",this.currentPage);
      equipment_brand_get(this.query).then((res) => {
        console.log(this.tableData, "new");
        this.tableData = res.data.result;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
      // console.log("当前页面显示xxx条", val,"当前界面是xxx页",this.currentPage);
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: "",
      };
      console.log(this.query);
      equipment_brand_get(this.query).then((res) => {
        console.log(this.tableData, "new");
        this.tableData = res.data;
      });
    },

    createFirstLevel() {
      equipment_brand_create(this.formAdd).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功添加");
          this.getFirstLevel()
        } else {
          this.$message.info(res.msg);
        }
      });
      console.log(this.formAdd, "添加的数据");
      this.dialogFormVisibleAdd = false;
    },
    addFormButton() {
      this.dialogFormVisibleAdd = true;
    },
    updateFirstLevel() {
     
      this.formEdit["brand_id"] = this.formEdit["id"];
      // Reflect.deleteProperty(this.formEdit,"id")
      Reflect.deleteProperty(this.formEdit, "id");
      console.log(this.formEdit);
      equipment_brand_update(this.formEdit).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功修改");
          this.getFirstLevel();
        } else {
          this.$message.info(res.msg);
        }
      });
      this.dialogFormVisibleEdit = false;
    },
    getFirstLevel() {
      equipment_brand_get(this.query).then((res) => {
        this.tableData = res.data;
        this.total = res.data.total;
      });
    },
    handleFormSubmit(formData){
      this.query.keyword = formData.keyword;
      equipment_brand_get(this.query).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.result;
      });
    },
    handleButtonClick(button,formData){
      if (button.action === "search") {
        this.handleFormSubmit(formData);
      } else if (button.action === "create") {
        this.addFormButton();
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
